import { apacSoftwarePackages } from './pages/softwarePackages/apac.softwarePackages';

export const apac = {
    myGeotabLogin: {
        loginText: 'Log in',
        registerText: 'Register',
    },
    badRequest: {
        title: '400 Bad Request | Geotab',
        heading: 'Oh no!',
        description:
            'There seems to be a client error. Make sure the request you are making or page you are trying to reach is valid.',
        description2: 'Try searching or go back to',
        homepageLink: 'our homepage',
    },
    notFound: {
        title: '404 Not Found | Geotab',
        heading: 'Oh no!',
        description: 'The page you were looking for could not be found.',
        description2: 'Try searching or go back to',
        homepageLink: 'our homepage',
    },
    salesforce: {
        formLanguage: 'Southeast Asia',
        demoFormDefaultCampaignId: '7012J000001YhJrQAK',
    },
    softwarePackages: apacSoftwarePackages,
    micrositeForm: {
        title: 'Connect with an authorised reseller',
        subTitle:
            'Let us show you how simple it is to use our web-based software and telematics devices to manage your fleet.',
        countryName: '',
        firstName: 'First name',
        lastName: 'Last name',
        company: 'Company',
        email: 'Email',
        fleetSize: 'Fleet size',
        phone: 'Phone',
        country: 'Country',
        state: 'State/Province',
        city: 'City',
        submitButton: 'Submit',
        tooltip: {
            pleaseFillOutThisField: 'Please fill out this field',
            pleaseSelectAnItem: 'Please select an item in the list',
        },
    },
    regionSelection: {
        seo: {
            title: 'Region Selection',
            description:
                'Select your country or region to learn about Geotab\'s fleet management software and vehicle tracking devices.',
        },
        title: 'Select your region',
        regions: {
            northSouthAmerica: 'North and South America',
            europe: 'Europe',
            asiaAustraliaMiddleEast: 'Asia, Australia, and Middle East',
        },
        regionChangeText: 'Change language',
    },
    formPlaceholders: {
        firstNamePlaceholder: 'Enter a first name\u2026',
        lastNamePlaceholder: 'Enter a last name\u2026',
        companyPlaceholder: 'Enter a company\u2026',
        emailPlaceholder: 'Enter an email\u2026',
        fleetPlaceholder: 'Select a fleet size\u2026',
        phonePlaceholder: 'Enter a phone number\u2026',
        countryPlaceholder: 'Select a country\u2026',
        cityPlaceholder: 'Enter a city\u2026',
        commentsPlaceholder: 'Enter any questions/comments\u2026',
        fullNamePlaceholder: 'Enter a full name\u2026',
        messagePlaceholder: 'Enter a message\u2026',
        identityPlaceholder: 'Select a category\u2026',
        surnamePlaceholder: 'Enter a surname\u2026',
        statePlaceholder: 'Select a state\u2026',
        provincePlaceholder: 'Select a province\u2026',
        titlePlaceholder: 'Select a title\u2026',
        userTitlePlaceholder: 'Enter a title\u2026',
        industryPlaceholder: 'Select an industry category\u2026',
    },
    aboutGeotab: {
        main: 'About Geotab',
        content:
            'Geotab is a global leader in connected vehicle and asset solutions, empowering fleet efficiency and management. We leverage advanced data analytics and AI to transform fleet performance, safety, and sustainability, reducing cost and driving efficiency. Backed by top data scientists and engineers, we serve over 55,000 global customers, processing 80 billion data points daily from more than 4.7 million vehicle subscriptions. Geotab is trusted by Fortune 500 organisations, mid-sized fleets, and the largest public sector fleets in the world, including the US Federal Government. Committed to data security and privacy, we hold FIPS 140-3 and FedRAMP authorisations. Our open platform, ecosystem of outstanding partners, and Marketplace deliver hundreds of fleet-ready third-party solutions. This year, we\'re celebrating 25 years of innovation.  Learn more at <a href="https://www.geotab.com/apac" target="_blank">www.geotab.com/apac</a>, and follow us on <a href="https://www.linkedin.com/company/geotab/" target="_blank" rel="noopener noreferrer">LinkedIn</a> or visit our <a href="https://www.geotab.com/apac/blog/" target="_blank">blog</a>.',
        rights: 'All Rights Reserved.',
        disclaimer:
            'This white paper is intended to provide information and encourage discussion on topics of interest to the telematics community. Geotab is not providing technical, professional or legal advice through this white paper. While every effort has been made to ensure that the information in this white paper is timely and accurate, errors and omissions may occur, and the information presented here may become out-of-date with the passage of time.',
    },
    aboutAuthor: {
        about: 'About',
        currentRole: 'Current role',
        experties: 'Expertise',
        accomplishments: 'Accomplishments',
        education: 'Education',
        publications: 'Publications',
    },
    mobileStickyBanner: {
        text: 'Schedule a demo',
        link: '/apac/request-demo/',
    },
    breadcrumb: {
        home: 'Home',
    },
    contactSection: {
        title: 'CONTACT US',
        subHeading: 'How can we direct your inquiry?',
        salesInquiryText: 'Sales inquiry',
        salesInquiryImageAlt: 'Price tag icon',
        salesInquiryLink: '/apac/contact-us/sales-inquiry/',
        customerSupportText: 'Customer support',
        customerSupportImageAlt: 'Customer chat icon',
        customerSupportLink: 'https://community.geotab.com/s/?language=en_US',
        contactLinks: [
            {
                title: 'Request demo',
                href: 'https://www.geotab.com/apac/request-demo/',
            },
            {
                title: 'Become a Marketplace Partner',
                href: 'https://marketplace.geotab.com/',
            },
            {
                title: 'Become a reseller',
                href: 'https://www.geotab.com/apac/contact-us/become-a-reseller/',
            },
        ],
    },
};
